import { TypeOfPatientsAdded, TypeOfStaffAllowedToAdd, TrueorFalse } from '../dataValidations'
export const CreateLoginValidation = (rows) => {
  // Validate headers
  const rowError = validateCsvHeadings(rows[0])
  if (rowError) {
    return `${rowError}`
  }
  // validate entries
  const dataRows = rows.slice(1, rows.length) // ignore header at 0 and get rest of the rows
  for (let i = 0; i < dataRows.length; i++) {
    const rowError = validateCsvRow(dataRows[i])
    if (rowError) {
      return `${rowError} on row ${i + 2}`
    }
  }
}
function validateCsvHeadings (row) {
  if (!row[0] || !/^HierarchyId$/.test(row[0])){
    return "Column 1 heading should be HierarchyId"
  } else if (!row[1] || !/^Username$/.test(row[1])){
    return "Column 2 heading should be Username"
  } else if (!row[2] || !/^Password$/.test(row[2])){
    return "Column 3 heading should be Password"
  } else if (!row[3] || !/^PhoneNumber$/.test(row[3])){
    return "Column 4 heading should be PhoneNumber"
  } else if (!row[4] || !/^SecondaryPhoneNumber$/.test(row[4])){
    return "Column 5 heading should be SecondaryPhoneNumber"
  } else if (!row[5] || !/^EmailAddress$/.test(row[5])){
    return "Column 6 heading should be EmailAddress"
  } else if (!row[6] || !/^TypeOfPatientsAdded$/.test(row[6])){
    return "Column 7 heading should be TypeOfPatientsAdded"
  } else if (!row[7] || !/^ViewOnly$/.test(row[7])){
    return "Column 8 heading should be ViewOnly"
  } else if (!row[8] || !/^CanDeletePatients$/.test(row[8])){
    return "Column 9 heading should be CanDeletePatients"
  } else if (!row[9] || !/^AccessIndiaTbPublic$/.test(row[9])){
    return "Column 10 heading should be AccessIndiaTbPublic"
  } else if (!row[10] || !/^AccessIndiaTbPrivate$/.test(row[10])){
    return "Column 11 heading should be AccessIndiaTbPrivate"
  } else if (!row[11] || !/^TypeOfStaffAllowedToAdd$/.test(row[11])){
    return "Column 12 heading should be TypeOfStaffAllowedToAdd"
  } else if (!row[12] || !/^AccessType$/.test(row[12])){
    return "Column 13 heading should be AccessType"
  }
}
function validateCsvRow (row) {
  if (!row[0] || !/^[1-9][0-9]*$/.test(row[0])){
    return "Column 1 should have a valid HierarchyId"
  } else if (!row[1] || !/^([a-zA-Z0-9]*-* *_*[a-zA-Z0-9]*)+$/.test(row[1])){
    return "Column 2 should have a valid Username"
  } else if (!/^[0-9]*$/.test(row[3])){
    return "Column 4 should have a valid PhoneNumber"
  } else if (!/(^[0-9]+$|^$)/.test(row[4])){
    return "Column 5 should have a valid SecondaryPhoneNumber"
  } else if (!row[6] || !TypeOfPatientsAdded(row[6])){
    return "Column 7 should have a valid TypeOfPatientsAdded"
  } else if (!row[7] || !TrueorFalse(row[7])){
    return "Column 8 should have a valid ViewOnly"
  } else if (!row[8] || !TrueorFalse(row[8])){
    return "Column 9 should have a valid CanDeletePatients"
  } else if (!row[9] || !TrueorFalse(row[9])){
    return "Column 10 should have a valid AccessIndiaTbPublic"
  } else if (!row[10] || !TrueorFalse(row[10])){
    return "Column 11 should have a valid AccessIndiaTbPrivate"
  } else if (!row[11] || !TypeOfStaffAllowedToAdd(row[11])){
    return "Column 12 should have a valid TypeOfStaffAllowedToAdd"
  } else if (!row[12] || !/^([A-Z]*-*_*[A-Z]*)+$/.test(row[12])){
    return "Column 13 should have a valid AccessType"
  } else if (/^STAFF$/.test(row[12])) {
    return "Please use Nikshay to create STAFF logins"
  } else if (/^PPSA-HUB-AGENT$/.test(row[12])){
    return "Please use Logins With New Hierarchies to create PPSA-HUB-AGENT logins"
  }
}
// module.exports = CreateLoginValidation
